<template>
  <div>
     <defaultFieldTypes
      :fieldAttributes="{
        ...fieldAttributes,
        associatedOption: associatedOption,
        ignoreEmptyAdd:true,
        fieldAttrInput: { ...fieldAttributes.fieldAttrInput, disabled: disableProject  } }"
      :field="field"
      :templateContent="result"
      v-on="$listeners"
      :value="value"
    ></defaultFieldTypes>
  </div>
</template>
<script>
import defaultFieldTypes from "@/commonComponents/defaultFieldTypes.vue";
export default {
  components: { defaultFieldTypes },
  props: ["fieldAttributes", "field", "result", "value"],
  data() {
    return {
      associatedOption: this.fieldAttributes.associatedOption,
      disableProject:false

    };
  },
  created() {
    let orgaID = this.result.valueLine.organisations;
    this.getOrganisationProjects(orgaID);
  },
  watch: {
    "result.valueLine.organisations": function (orgaID) {
      this.getOrganisationProjects(orgaID);
    },
  },
  methods: {
    getOrganisationProjects(orgaID) {
      this.disableProject=true;
      let caller = this;
      var options = {
        function: "getOrganisationProjects",
        requestType: "ajax",
        orgaID: orgaID,
      };
      this.frameworkAxiosRequest({
        method: 'post',
        url: 'serve.php?f=administration&f2=projectAdministration',
        data: options,
      })
          .then(function (response) {
          caller.associatedOption = response.data.result.projects;
          console.log(caller.associatedOption);
          caller.$emit("input", response.data.result.currentDomainId);
          caller.disableProject=false;
        })
        .catch(function (response) {
          console.log(response);
        });
    },
  },
};
</script>